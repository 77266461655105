import React, { useCallback } from 'react';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToAgencies,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrAgencyComponentsDocument } from 'schemas/query/agencies/fdr-paginated-agency-recommender.generated';
import { FdrAgencyRecommender } from './fdr-agency-recommender';

interface IFdrAgencyPageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrAgencyRecommender' }
  >;
}

const FdrAgencyPageComponent: React.FC<IFdrAgencyPageComponentProps> = ({
  component,
}) => {
  const mapVariables = useCallback(
    (page, perPage) => {
      return createPageComponentVariables(component.id, page, perPage);
    },
    [component.id]
  );

  const { fetchItems, loading } = useFdrPageComponentsFetch(
    FdrAgencyComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToAgencies
  );

  return (
    <FdrAgencyRecommender
      fgColor={component.fgColor}
      bgColor={component.bgColor}
      title={component.title}
      agencies={component.agencies.agenciesPage || []}
      total={component.agencies.pagination.total}
      fetchItems={fetchItems}
      loading={loading}
    />
  );
};

export default FdrAgencyPageComponent;
