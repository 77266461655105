import React, { useCallback } from 'react';
import { isFdrThGeoObject } from 'components/domain/fdr-geo/fdr-geo.utils';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToThemeGeo,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrCommonGridRecommender } from 'components/domain/fdr-page-components/recommenders/common/fdr-common-grid-recommender';
import { FdrGeoRecommenderItem } from 'components/domain/fdr-page-components/recommenders/geo/fdr-geo-recommender-item';
import { FdrThemeRecommender } from 'components/domain/fdr-page-components/recommenders/theme/fdr-theme-recommender';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrThemeGeoComponentsDocument } from 'schemas/query/theme-geo/fdr-paginated-theme-geo-recommender.generated';

interface IFdrThemeGeoPageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrThemeGeoRecommender' }
  >;
}

const FdrThemeGeoPageComponent: React.FC<IFdrThemeGeoPageComponentProps> = ({
  component,
}) => {
  const mapVariables = useCallback(
    (page, perPage) => {
      return createPageComponentVariables(component.id, page, perPage);
    },
    [component.id]
  );

  const { fetchItems } = useFdrPageComponentsFetch(
    FdrThemeGeoComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToThemeGeo
  );

  if (component.displayType === 'SWIPE')
    return (
      <FdrThemeRecommender
        total={component.itemsPage.pagination.total}
        title={component.title}
        id={component.id}
        bgColor={component.bgColor}
        fontColor={component.fgColor}
        items={component.itemsPage.items.map(({ theme }) => ({
          ...theme,
          link: theme.webMeta.descriptionWebMeta?.link,
        }))}
        displayType={component.displayType}
        fetchItems={fetchItems}
      />
    );

  return (
    <FdrCommonGridRecommender
      key={`geo-rec-section-${component.id}`}
      bgColor={component.bgColor}
      fontColor={component.fgColor}
      title={component.title}
      items={component.itemsPage.items.map(({ geo }) => ({
        ...geo,
        link:
          isFdrThGeoObject(geo) && geo.webMeta.descriptionWebMeta?.link
            ? geo.webMeta.descriptionWebMeta.link
            : undefined,
      }))}
      pagination={component.itemsPage.pagination}
      recommendationItem={FdrGeoRecommenderItem}
      fetchItems={fetchItems}
    />
  );
};

export default FdrThemeGeoPageComponent;
