import React, { PropsWithChildren, ReactElement } from 'react';
import { AgencyRecommenderContent } from './fdr-agency-recommender-content';
import { AgencyRecommenderContentSkeleton } from './fdr-agency-recommender-content-skeleton';
import { IAgencyRecommendationProps } from './fdr-agency-recommender.types';

export function FdrAgencyRecommender(
  props: PropsWithChildren<IAgencyRecommendationProps>
): ReactElement {
  if (props.loading) {
    return (
      <AgencyRecommenderContentSkeleton
        agencyMapSearch={props.agencyMapSearch}
        bgColor={props.bgColor}
        fgColor={props.fgColor}
        title={props.title}
      />
    );
  }

  return <AgencyRecommenderContent {...props} />;
}
