import { load } from 'cheerio';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { FdrLink, FdrLinkType } from '@hotelplan/supergraph-api';
import { useFdrGclIdValue } from './useFdrGclidValue';

export const GCLID_COOKIE_KEY = '_gcl_aw';
const JOTFORM_REGEXP = /jotform/;

export const useFdrGetClientGclidValue = (
  ssrGclid?: string | undefined,
  onlyClient?: boolean
): string | undefined => {
  const [gclid, setGclid] = useState(ssrGclid);

  const getClientGclidValue = () => {
    const clientGclId = Cookies.get(GCLID_COOKIE_KEY);

    if (clientGclId) {
      setGclid(clientGclId);
    }
  };

  useEffect(() => {
    if (gclid || typeof window === 'undefined' || onlyClient) return;

    window.addEventListener('load', getClientGclidValue);

    return () => {
      window.removeEventListener('load', getClientGclidValue);
    };
  }, [ssrGclid, gclid, onlyClient]);

  if (onlyClient) {
    return Cookies.get(GCLID_COOKIE_KEY);
  }

  return gclid;
};

export const addFdrGclIdQueryToJotformUrl = (
  url: string,
  gclidValue: string | undefined
): string => {
  if (!gclidValue) return url;

  const gclid = gclidValue.split('.')[2];

  const prefix = /[?]/.test(url) ? '&' : '?';
  const gclidQuery = `${prefix}gclid=${gclid}`;

  return url + gclidQuery;
};

export const getFdrLinkWithJotform = <T extends FdrLink>(
  link: T,
  gclidValue: string | undefined
): T => {
  if (!link) return undefined;
  if (link.type !== FdrLinkType.External) return link;

  const href = link?.url;

  if (!JOTFORM_REGEXP.test(href) || !gclidValue) return link;

  const url = addFdrGclIdQueryToJotformUrl(href, gclidValue);

  return {
    ...link,
    url,
  };
};

export const useFdrGclid = (): string | undefined => {
  const ssrGclid = useFdrGclIdValue();
  return useFdrGetClientGclidValue(ssrGclid);
};

export const useFdrGetLinkWithJotform = <T extends FdrLink>(
  link: T,
  providedGclid?: string
): T => {
  const gclid = useFdrGclid();
  return getFdrLinkWithJotform(link, providedGclid || gclid);
};

export const useFdrGetLinkUrlWithJotform = (url: string): string => {
  const gclid = useFdrGclid();
  return addFdrGclIdQueryToJotformUrl(url, gclid);
};

export const addGclidToJotformInHTMLText = (
  htmlText: string,
  gclid: string
): string => {
  if (!JOTFORM_REGEXP.test(htmlText)) return htmlText;

  const $ = load(htmlText);

  $(`a`).each((index, element) => {
    const href = $(element).attr(`href`);
    if (href && JOTFORM_REGEXP.test(href)) {
      $(element).attr(`href`, addFdrGclIdQueryToJotformUrl(href, gclid));
    }
  });

  return $.html();
};
