import { createMediaImageProps } from 'components/domain/fdr-media/fdr-media.utils';
import { FdrMediaGalleryFragment } from 'schemas/fragment/media-gallery/fdr-media-gallery.generated';

export const isAllImages = (
  media: FdrMediaGalleryFragment['media']
): boolean => {
  if (!media) return false;

  return media?.every(({ __typename }) => __typename === 'FdrImage');
};

export const mapGalleryMediaToImageMediaItems = (
  media: FdrMediaGalleryFragment['media']
) => {
  return (
    media?.map(mediaItem => {
      if (mediaItem?.__typename != 'FdrImage') return null;

      const fdrImage = mediaItem;

      return {
        __typename: `ImageMediaItem`,
        image: createMediaImageProps<
          'mediaImageResized',
          'mediaImagePreviewResized'
        >(fdrImage, 'mediaImageResized'),
        resource: fdrImage.rawUrl,
        preview: fdrImage.mediaImagePreviewResized
          ? createMediaImageProps(fdrImage, 'mediaImagePreviewResized')
          : createMediaImageProps(fdrImage, 'mediaImageResized'),
        title: fdrImage.alt,
        coordinates: fdrImage.coordinates,
      };
    }) || []
  );
};
