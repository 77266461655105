import { IImageProps, IResizedUrlData } from '@hotelplan/core.basis.bs-img';
import { FdrResizedImage } from '@hotelplan/supergraph-api';
import { FdrMediaImageWithPreview } from './fdr-media.types';

export function mapFdrResizedImagesToResizedUrlData(
  fdrResized: FdrResizedImage[]
): IResizedUrlData[] {
  return fdrResized.map(item => ({
    id: item.id,
    url: item.link.url,
  }));
}

export const createMediaImageProps = <
  FullImage extends string,
  PreviewImage extends string
>(
  mediaItem: FdrMediaImageWithPreview<FullImage, PreviewImage>,
  resizedField: FullImage | PreviewImage
): IImageProps => {
  return {
    ...mediaItem,
    src: mediaItem.rawUrl,
    resized: mapFdrResizedImagesToResizedUrlData(mediaItem[resizedField]),
  };
};
