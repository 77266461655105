import styled from 'styled-components';
import { Container } from 'components/domain/container';

export const RecommendationListContainer = styled(Container)(
  ({ theme: { media, space } }) => ({
    padding: `50px ${space[3]}`,
    paddingRight: 0,
    scrollMarginTop: '100px',
    [media.tablet]: {
      padding: '80px 40px',
    },
  })
);
