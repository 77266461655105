import React, { ReactElement } from 'react';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { mapOpeningHoursToSchedules } from '@hotelplan/fdr.lib.misc.schedule';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrOpeningHours } from '@hotelplan/supergraph-api';
import { FdrHelpOverlayBox } from 'components/candidate/fdr-help-overlay';
import { FdrSchedule } from 'components/candidate/fdr-schedule';
import { FdrHelpOverlayBoxPhoneFragment } from 'schemas/query/help-overlay/fdr-help-overlay.generated';
import { FlaglineText, ThirdHeading } from 'theme/headings';
import {
  FdrHelpOverlayContent,
  FdrInfoCardWrapper,
  FdrHelpOverlayFakeLinkWrapper,
  FdrHelpOverlayPhoneLink,
} from './fdr-help-overlay.styles';

interface IFdrHelpOverlayPhoneBoxProps {
  box: FdrHelpOverlayBoxPhoneFragment;
  boxesCount: number;
  onClick?(): void;
  openingHours?: FdrOpeningHours;
  phoneNumber?: string;
  boxTitle?: string;
  timezone?: string;
}

export function FdrHelpOverlayPhoneBox({
  box,
  boxesCount,
  onClick,
  boxTitle,
  phoneNumber,
  openingHours,
  timezone,
}: IFdrHelpOverlayPhoneBoxProps): ReactElement {
  const { mobile } = useDeviceType();

  if (!box) return null;

  return (
    <FdrHelpOverlayBox boxesCount={boxesCount} onClick={onClick}>
      <FdrHelpOverlayPhoneLink href={`tel:${phoneNumber}`}>
        <FdrInfoCardWrapper infoCardImage={box.icon}>
          <div className="help-overlay-content-wrap">
            <FdrHelpOverlayContent>
              {!mobile && (
                <FlaglineText className="image-tagline">
                  {boxTitle}
                </FlaglineText>
              )}
              <ThirdHeading className="image-title">
                {box.mainTitle}
              </ThirdHeading>
              <FdrHelpOverlayFakeLinkWrapper>
                <BsIcon name={'chevron-long-right'} />
                <span className="link-text">{phoneNumber}</span>
              </FdrHelpOverlayFakeLinkWrapper>
            </FdrHelpOverlayContent>
          </div>
          <FdrSchedule
            showStatusIcon
            className="schedule"
            timezone={timezone}
            openingHours={openingHours}
            mapCustomOpeningHoursToSchedules={mapOpeningHoursToSchedules}
          />
        </FdrInfoCardWrapper>
      </FdrHelpOverlayPhoneLink>
    </FdrHelpOverlayBox>
  );
}
