import React, { ReactElement, useCallback, useState } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrLink } from '@hotelplan/supergraph-api';
import { IFetchComponentResponse } from 'components/domain/fdr-page-components/fdr-page-components.types';
import FdrBlogArticleItem from 'components/domain/fdr-page-components/recommenders/blog-article/fdr-blog-article-item';
import useLazyFetchPagination from 'components/domain/pagination/useLazyFetchPagination';
import { RecommendationListContainer } from 'components/domain/recommendations/Recommendations.styles';
import { FdrBlogArticleFragment } from 'schemas/fragment/blog-articles/fdr-all-blog-articles.generated';
import { FdrBlogArticleRecommenderFragment } from 'schemas/fragment/recommenders/blog-article/blog-article-recommneder.generated';
import {
  BlogArticleLink,
  BlogArticleRecommendationSliderSection,
} from './fdr-blog-article.styles';

const BLOG_ARTICLES_PER_PAGE = 3;

interface IBlogArticleRecommendationSectionProps {
  component: FdrBlogArticleRecommenderFragment;
  fetchItems?(
    page: number,
    perPage: number
  ): Promise<IFetchComponentResponse<FdrBlogArticleFragment>>;
}

function BlogArticleRecommendationsSection({
  component,
  fetchItems,
}: IBlogArticleRecommendationSectionProps): ReactElement {
  const { mobile } = useDeviceType();
  const perPage = BLOG_ARTICLES_PER_PAGE;
  const [imageHeight, setImageHeight] = useState<number | undefined>();
  const updateImageHeight = useCallback((height: number) => {
    setImageHeight(height);
  }, []);

  const articles = component?.blogArticleItems?.blogArticles || [];

  const { items, page, prevPage, nextPage, setPage } =
    useLazyFetchPagination<FdrBlogArticleFragment>(articles, {
      total: component.blogArticleItems.pagination.total,
      perPage,
      fetchItems,
    });

  if (!items.length) return null;

  return (
    <>
      <RecommendationListContainer id={component.id}>
        <BlogArticleRecommendationSliderSection
          name={component.title}
          total={component.blogArticleItems.pagination.total}
          page={page}
          perPage={perPage}
          prevPage={prevPage}
          nextPage={nextPage}
          setPage={setPage}
          showTotal={false}
          imageHeight={imageHeight}
          isSliderPagination={!mobile}
        >
          {items.map(function (item) {
            return (
              <BlogArticleLink
                key={`blog-article-${item.publicId}`}
                link={item.webMeta.link as FdrLink}
              >
                <FdrBlogArticleItem
                  item={item}
                  onImageHeightChange={updateImageHeight}
                />
              </BlogArticleLink>
            );
          })}
        </BlogArticleRecommendationSliderSection>
      </RecommendationListContainer>
    </>
  );
}

export default BlogArticleRecommendationsSection;
