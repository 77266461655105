import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import {
  getFdrProductRecommenderProducts,
  mapFdrPageComponentsResponseToProducts,
  createPageComponentVariables,
  mapRecommenderProduct,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrProductComponentsDocument } from 'schemas/query/product/fdr-paginated-product-recommender.generated';
import { FdrProductRecommender } from './fdr-product-recommender';

interface IFdrProductPageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrProductRecommender' }
  >;
}

const FdrProductPageComponent: React.FC<IFdrProductPageComponentProps> = ({
  component,
}) => {
  const [t] = useTranslation();
  const products = getFdrProductRecommenderProducts(component);

  const mapVariables = useCallback(
    (page, perPage) => {
      return createPageComponentVariables(component.id, page, perPage);
    },
    [component.id]
  );

  const { fetchItems, loading } = useFdrPageComponentsFetch(
    FdrProductComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToProducts
  );

  return (
    <FdrProductRecommender
      testId="product-recommendations-section"
      id={component.id}
      component={{
        ...component,
        name: component.title,
        items: products.map(mapRecommenderProduct),
        total: component.productPage.pagination.total,
      }}
      sectionHeaderTitle={t('product.recommender.section.title')}
      loading={loading}
      fetchItems={fetchItems}
    />
  );
};

export default FdrProductPageComponent;
