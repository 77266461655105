import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

interface ILazyPaginationOptions<T> {
  perPage: number;
  total: number;
  page?: number;
  fetchItems?: (
    page: number,
    perPage: number
  ) => Promise<ILazyPaginationFetchResponse<T>>;
}

interface ILazyPaginationFetchResponse<T> {
  items: T[];
  pagination: {
    page: number;
    perPage: number;
  };
}

function useLazyFetchPagination<T>(
  initialItems: T[],
  options: ILazyPaginationOptions<T>
): {
  items: T[];
  page: number;
  hasMore: boolean;
  nextPage: () => void;
  prevPage: () => void;
  setPage: (page: number) => void;
} {
  const { page: initialPage = 0, perPage = 3, total, fetchItems } = options;
  const [page, setPage] = useState<number>(initialPage);
  const [items, setItems] = useState<T[]>(initialItems);
  const prevInitialItemsRef = useRef(initialItems);

  const isInitialItemsChanged = useMemo(() => {
    return !isEqual(prevInitialItemsRef.current, initialItems);
  }, [initialItems]);

  const fetchPageItems = useCallback(
    async (targetPage: number) => {
      if (fetchItems && isFetchNeeded(targetPage, perPage, items)) {
        const result = await fetchItems(targetPage, perPage);
        let newItems = [];

        if (result?.items?.length) {
          newItems = result.items;
        }

        setItems(prevState => {
          const newState = [...prevState];

          newItems.forEach((item, itemIndex) => {
            newState[targetPage * perPage + itemIndex] = item;
          });

          return newState;
        });
      }
    },
    [fetchItems, items, perPage]
  );

  useEffect(() => {
    if (isInitialItemsChanged) {
      prevInitialItemsRef.current = initialItems;
      setItems(initialItems);
      setPage(initialPage);
      fetchPageItems(initialPage);
    }
  }, [initialItems]);

  useEffect(() => {
    fetchPageItems(page);
  }, [page]);

  return useMemo(() => {
    const maxPages = Math.ceil(total / perPage) - 1;

    return {
      items,
      page,
      hasMore: page < maxPages,
      nextPage() {
        setPage(prev => (prev < maxPages ? prev + 1 : prev));
      },
      prevPage() {
        setPage(prev => (prev > 0 ? prev - 1 : 0));
      },
      setPage(pageIndex: number) {
        if (pageIndex < 0 || pageIndex > maxPages) {
          return;
        }

        setPage(pageIndex);
      },
    };
  }, [total, perPage, items, page]);
}

function isFetchNeeded<T>(page: number, perPage: number, items: T[]) {
  const pageItems = items.slice(page * perPage, (page + 1) * perPage);

  return !pageItems.length || pageItems.find(i => typeof i === 'undefined');
}

export default useLazyFetchPagination;
