import { TMediaItem } from '@hotelplan/fdr.regular.fusion.fdr-media-autoplay-gallery';
import { FdrImage } from '@hotelplan/supergraph-api';

const possibleFieldNames = [
  `resized`,
  `mediaPreviewResized`,
  `mediaImageResized`,
];

export const mapImageCriteriaField = (
  image: Omit<FdrImage, 'resized'>
): FdrImage => {
  const resizedField = possibleFieldNames.find(fieldName => image[fieldName]);

  return {
    ...image,
    resized: image[resizedField],
  };
};

export const mapMediaItemsImageCriteriaField = (
  items: Array<TMediaItem>
): Array<TMediaItem> => {
  if (!items || !items.length) return [];

  return items.map(media => {
    switch (media.__typename) {
      case 'FdrAudio':
      case 'FdrExternalMedia':
      case 'FdrPdf':
      case 'FdrVideo':
        return {
          ...media,
          preview: mapImageCriteriaField(media?.preview),
        };
      case 'FdrImage':
        return mapImageCriteriaField(media);
      default:
        return media;
    }
  });
};
