import React from 'react';
import {
  BenefitItem,
  BenefitItemContainer,
  BenefitItemNumber,
  BenefitItemText,
} from './fdr-benefit-items.styles';

interface IBenefitItemsProps {
  items: { text?: string }[];
}

const FdrBenefitItems: React.FC<IBenefitItemsProps> = props => {
  const { items } = props;

  return (
    <BenefitItemContainer>
      {items.map((item, i) => {
        return (
          <BenefitItem key={i}>
            <BenefitItemNumber>{i + 1}</BenefitItemNumber>
            <BenefitItemText dangerouslySetInnerHTML={{ __html: item.text }} />
          </BenefitItem>
        );
      })}
    </BenefitItemContainer>
  );
};

export default FdrBenefitItems;
