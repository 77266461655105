import React from 'react';
import styled from 'styled-components';
import { Link } from '@hotelplan/components.common.link';
import { FdrBenefitsComponent } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';
import FdrBenefitItems from 'components/domain/fdr-benefits-section/fdr-benefit-items';
import { useFdrGetLinkWithJotform } from 'components/domain/fdr-jotform';
import { NavigationIds } from 'components/domain/geo/navigation/GeoNavigation.types';

interface IBenefitsSectionProps {
  component: Omit<FdrBenefitsComponent, 'inPageNavigable'>;
}

export const BenefitSectionContent = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'paperGrey',
  })
);

export const BenefitContainer = styled(Container)(({ theme: { space } }) =>
  sx2CssThemeFn({
    padding: [`50px ${space[3]}`, '80px 20px'],
  })
);

export const BenefitButtonContainer = styled.div(
  ({ theme }) => theme.requestButton.primary,
  ({ theme: { space } }) =>
    sx2CssThemeFn({
      marginTop: [space[6], '40px'],
      textAlign: 'center',
      display: 'block',
    })
);

export const BenefitButtonLink = styled(Link)(
  ({ theme }) => theme.buttons.primary
);

export const BenefitText = styled.div(({ theme: { space, fontSizes } }) =>
  sx2CssThemeFn({
    marginBottom: [space[6], '40px'],
    fontSize: [fontSizes[2], fontSizes[3]],
    lineHeight: [1.375, 1.6],
    width: ['initial', '800px'],
    h2: {
      fontSize: [fontSizes[7], fontSizes[8]],
      lineHeight: 1.3,
      marginBottom: space[4],
    },
  })
);

const FdrBenefitsSection: React.FC<IBenefitsSectionProps> = ({ component }) => {
  const { text, benefitItems, buttonLabel, buttonLink } = component;

  const linkWithJotform = useFdrGetLinkWithJotform(buttonLink);
  const target = buttonLink.openMethod || '_self';

  return (
    <BenefitSectionContent id={NavigationIds.BENEFITS}>
      <BenefitContainer>
        <BenefitText dangerouslySetInnerHTML={{ __html: text }} />
        <FdrBenefitItems items={benefitItems} />
        <BenefitButtonContainer>
          <BenefitButtonLink
            className={'request-button'}
            target={target}
            href={linkWithJotform.url}
          >
            {buttonLabel}
          </BenefitButtonLink>
        </BenefitButtonContainer>
      </BenefitContainer>
    </BenefitSectionContent>
  );
};

export default FdrBenefitsSection;
