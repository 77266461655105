import { ReactElement } from 'react';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { FdrMenu } from '@hotelplan/supergraph-api';
import {
  FdrFooterMenuColumn,
  FdrFooterMenuLinksWrapper,
  FdrFooterMenuMainItem,
  FdrFooterMenuSubItem,
  FdrFooterMenuWrapper,
} from './fdr-footer-menu.styles';

interface IFdrFooterMenuProps {
  navLinks: Array<FdrMenu>;
}

export function FdrFooterMenu({ navLinks }: IFdrFooterMenuProps): ReactElement {
  return (
    <FdrFooterMenuWrapper>
      {navLinks?.map((menuLink, index) => (
        <FdrFooterMenuColumn key={`footer-menu-column-${index}`}>
          <FdrFooterMenuMainItem
            data-id="footer-main-link"
            fullWidth={!menuLink.menuItems.length}
          >
            <FdrRoute link={menuLink.link}>{menuLink.caption}</FdrRoute>
          </FdrFooterMenuMainItem>
          {menuLink.menuItems.length > 0 ? (
            <FdrFooterMenuLinksWrapper>
              {menuLink.menuItems.map((menuItem, i) => (
                <FdrFooterMenuSubItem
                  data-id={`footer-sub-link-${menuItem.caption.toLowerCase()}`}
                  key={`footer-menu-sub-item-${i}`}
                >
                  <FdrRoute link={menuItem.link}>{menuItem.caption}</FdrRoute>
                </FdrFooterMenuSubItem>
              ))}
            </FdrFooterMenuLinksWrapper>
          ) : null}
        </FdrFooterMenuColumn>
      ))}
    </FdrFooterMenuWrapper>
  );
}
