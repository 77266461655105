import styled from 'styled-components';
import { Container } from 'components/domain/container';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrFaq } from '@hotelplan/fdr.regular.fusion.fdr-faq';
import { TextBlockContainer } from 'components/domain/text-block/TextBlockContainer';

export const RichTextContainer = styled(TextBlockContainer)(
  ({ theme }) => theme.text.richText,
  sx2CssThemeFn({
    paddingBottom: ['50px', 9],
    paddingTop: ['50px', 9],
    overflowX: 'unset',
    '&.small-padding-bottom': {
      paddingBottom: [4, '40px'],
    },
    '&.small-padding-top': {
      paddingTop: [4, '40px'],
    },
    '&.none-padding-bottom': {
      paddingBottom: [0, 0],
      '.text-block-text > *:last-child': {
        marginBottom: 0,
      },
    },
    '&.none-padding-top': {
      paddingTop: [0, 0],
    },
    '.large-text-width': {
      width: '100%',
      maxWidth: '100%',
      '.text-block-text': {
        width: '100%',
      },
    },
    '.text-block-text': {
      fontSize: '15px',
    },
    '&.anchor-section, .anchor-section': {
      scrollMarginTop: ['0', '150px'],
      scrollPaddingTop: ['0', '150px'],
    },
  })
);

export const BackgroundWrapper = styled.div(
  sx2CssThemeFn({
    pb: [7, 9],
    pt: [6, 9],
    bg: 'background',
  })
);

export const FdrFaqContainer = styled(Container)(
  sx2CssThemeFn({
    maxWidth: [null, '858px'],
    '.accordionItem': {
      mr: [null, -3],
      ml: [null, -3],
      '.accordionContent': {
        fontSize: ['18px', 1],
      },
      '.accordionButton': {
        color: 'black',
        width: '100%',
        fontWeight: 'bold',
        py: '12px',
        px: ['20px', '26px'],
        textAlign: 'left',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  })
);

export const FdrFaqWrapper = styled(FdrFaq)<{ mt?: boolean; mb?: boolean }>(
  ({ mt, mb }) =>
    sx2CssThemeFn({
      mt: mt ? 5 : 0,
      mb: mb ? 5 : 0,
    })
);

export const MediaGalleryContainer = styled(Container)(
  sx2CssThemeFn({
    mb: [4, 5],
    pr: [0, 4],
    pl: [0, 4],
  })
);

export const FdrImageMediaWrapper = styled.div(
  sx2CssThemeFn({
    width: '100%',
    height: '100%',
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      left: 0,
      top: 0,
      background:
        'linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%)',
      width: '100%',
      height: '100%',
      opacity: '.5',
      zIndex: 1,
    },
    '.image-media': {
      width: '100%',
      height: ['100vh', '800px'],
      pointerEvents: 'none',
      alignSelf: 'center',
      objectFit: 'cover',
    },
    '.preview-image': {
      position: 'absolute',
      zIndex: 9999,
    },
  })
);
