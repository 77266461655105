import React from 'react';
import styled from 'styled-components';
import { Icon } from '@hotelplan/components.common.icon';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { TCommonRecommenderItem } from 'components/domain/fdr-page-components/fdr-page-components.types';
import {
  GridRecommendationItem,
  GridRecommendationMobileButtonItems,
  GridRecommendationTitle,
} from 'components/domain/recommendations/CommonGridRecommendationSection.styles';

interface IFdrGeoRecommenderItemProps {
  item: TCommonRecommenderItem;
}

export const GridRecommendationItemImage = styled(FdrImg)({
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'all 0.5s ease',
  maxHeight: '274px',
  '&:hover': {
    cursor: 'pointer',
  },
});

export const FdrGeoRecommenderItem: React.FC<IFdrGeoRecommenderItemProps> = ({
  item,
}) => {
  const { mobile } = useDeviceType();
  const { title, image, link, id } = item;

  return (
    <FdrRoute link={link} key={`geo-item-${id}`} className="geo-item-wrapper">
      {!mobile ? (
        <>
          <GridRecommendationItem className="geo-item">
            <GridRecommendationItemImage image={image} />
          </GridRecommendationItem>
          <GridRecommendationTitle className="geo-title">
            <span>{title}</span>
            <Icon name="chevron-long-right" className="icon" size="lg" />
          </GridRecommendationTitle>
        </>
      ) : (
        <GridRecommendationMobileButtonItems>
          <GridRecommendationTitle className="geo-title">
            <span>{title}</span>
            <Icon name="chevron-long-right" className="icon" size="lg" />
          </GridRecommendationTitle>
        </GridRecommendationMobileButtonItems>
      )}
    </FdrRoute>
  );
};
