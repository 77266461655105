import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrImageFragmentDoc } from 'schemas/fragment/regular/fdrImage.generated';
import { FdrResizedFragmentDoc } from 'schemas/fragment/regular/fdrResized.generated';
import { FdrLinkFragmentDoc } from 'schemas/fragment/regular/fdrLink.generated';
import { FdrGeoRecommenderItemFragmentDoc } from 'schemas/fragment/recommenders/geo-recommender-item/fdr-geo-recommender-item.generated';
import { FdrThemeFragmentDoc } from 'schemas/fragment/recommenders/theme/fdr-theme-recommender.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrThemeGeoComponentsQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  pagination: Types.FdrPaginationInput;
}>;

export type FdrThemeGeoComponentsQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | { __typename?: 'FdrGeoRecommender' }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrSrlButton' }
      | {
          __typename?: 'FdrThemeGeoRecommender';
          id: string;
          title?: string | null;
          bgColor?: string | null;
          fgColor?: string | null;
          displayType: string;
          itemsPage: {
            __typename?: 'FdrThemeGeoRecommenderItems';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<{
              __typename?: 'FdrThemeGeoPage';
              title: string;
              geo:
                | {
                    __typename: 'FdrContinent';
                    id: string;
                    title: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'FdrCountry';
                    id: string;
                    title: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'FdrCountryGroup';
                    id: string;
                    title: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'FdrDestination';
                    id: string;
                    title: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'FdrRegion';
                    id: string;
                    title: string;
                    webMeta: {
                      __typename?: 'FdrGeoWebMeta';
                      descriptionWebMeta?: {
                        __typename?: 'FdrMeta';
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      } | null;
                    };
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  }
                | {
                    __typename: 'FdrResort';
                    id: string;
                    title: string;
                    image?: {
                      __typename?: 'FdrImage';
                      alt: string;
                      description?: string | null;
                      copyright?: string | null;
                      rawUrl: string;
                      resized: Array<{
                        __typename?: 'FdrResizedImage';
                        id: string;
                        link: {
                          __typename?: 'FdrLink';
                          openMethod?: Types.FdrLinkOpenMethod | null;
                          legacyUrl?: string | null;
                          type: Types.FdrLinkType;
                          url: string;
                        };
                      }>;
                    } | null;
                  };
              theme: {
                __typename?: 'FdrTheme';
                id: string;
                title: string;
                image?: {
                  __typename?: 'FdrImage';
                  resized: Array<{
                    __typename?: 'FdrResizedImage';
                    id: string;
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  }>;
                } | null;
                webMeta: {
                  __typename?: 'FdrThemeWebMeta';
                  descriptionWebMeta?: {
                    __typename?: 'FdrMeta';
                    link: {
                      __typename?: 'FdrLink';
                      openMethod?: Types.FdrLinkOpenMethod | null;
                      legacyUrl?: string | null;
                      type: Types.FdrLinkType;
                      url: string;
                    };
                  } | null;
                };
              };
              image?: {
                __typename?: 'FdrImage';
                alt: string;
                description?: string | null;
                copyright?: string | null;
                rawUrl: string;
                resized: Array<{
                  __typename?: 'FdrResizedImage';
                  id: string;
                  link: {
                    __typename?: 'FdrLink';
                    openMethod?: Types.FdrLinkOpenMethod | null;
                    legacyUrl?: string | null;
                    type: Types.FdrLinkType;
                    url: string;
                  };
                }>;
              } | null;
              webMeta: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              };
            }> | null;
          };
        }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export type FdrPaginatedThemeGeoRecommenderFragment = {
  __typename?: 'FdrThemeGeoRecommender';
  id: string;
  title?: string | null;
  bgColor?: string | null;
  fgColor?: string | null;
  displayType: string;
  itemsPage: {
    __typename?: 'FdrThemeGeoRecommenderItems';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<{
      __typename?: 'FdrThemeGeoPage';
      title: string;
      geo:
        | {
            __typename: 'FdrContinent';
            id: string;
            title: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }
        | {
            __typename: 'FdrCountry';
            id: string;
            title: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }
        | {
            __typename: 'FdrCountryGroup';
            id: string;
            title: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }
        | {
            __typename: 'FdrDestination';
            id: string;
            title: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }
        | {
            __typename: 'FdrRegion';
            id: string;
            title: string;
            webMeta: {
              __typename?: 'FdrGeoWebMeta';
              descriptionWebMeta?: {
                __typename?: 'FdrMeta';
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              } | null;
            };
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          }
        | {
            __typename: 'FdrResort';
            id: string;
            title: string;
            image?: {
              __typename?: 'FdrImage';
              alt: string;
              description?: string | null;
              copyright?: string | null;
              rawUrl: string;
              resized: Array<{
                __typename?: 'FdrResizedImage';
                id: string;
                link: {
                  __typename?: 'FdrLink';
                  openMethod?: Types.FdrLinkOpenMethod | null;
                  legacyUrl?: string | null;
                  type: Types.FdrLinkType;
                  url: string;
                };
              }>;
            } | null;
          };
      theme: {
        __typename?: 'FdrTheme';
        id: string;
        title: string;
        image?: {
          __typename?: 'FdrImage';
          resized: Array<{
            __typename?: 'FdrResizedImage';
            id: string;
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          }>;
        } | null;
        webMeta: {
          __typename?: 'FdrThemeWebMeta';
          descriptionWebMeta?: {
            __typename?: 'FdrMeta';
            link: {
              __typename?: 'FdrLink';
              openMethod?: Types.FdrLinkOpenMethod | null;
              legacyUrl?: string | null;
              type: Types.FdrLinkType;
              url: string;
            };
          } | null;
        };
      };
      image?: {
        __typename?: 'FdrImage';
        alt: string;
        description?: string | null;
        copyright?: string | null;
        rawUrl: string;
        resized: Array<{
          __typename?: 'FdrResizedImage';
          id: string;
          link: {
            __typename?: 'FdrLink';
            openMethod?: Types.FdrLinkOpenMethod | null;
            legacyUrl?: string | null;
            type: Types.FdrLinkType;
            url: string;
          };
        }>;
      } | null;
      webMeta: {
        __typename?: 'FdrMeta';
        link: {
          __typename?: 'FdrLink';
          openMethod?: Types.FdrLinkOpenMethod | null;
          legacyUrl?: string | null;
          type: Types.FdrLinkType;
          url: string;
        };
      };
    }> | null;
  };
};

export const FdrPaginatedThemeGeoRecommenderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPaginatedThemeGeoRecommender' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrThemeGeoRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'displayType' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'themeGeoRecommenderItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'themeGeoPages' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              alias: { kind: 'Name', value: 'id' },
                              name: { kind: 'Name', value: 'publicId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'title' },
                        name: { kind: 'Name', value: 'combinedTitle' },
                      },
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'image' },
                        name: { kind: 'Name', value: 'combinedHero' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrImage' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resized' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'criteria' },
                                  value: {
                                    kind: 'ListValue',
                                    values: [
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_mobile',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '364',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '315',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'AUTO',
                                            },
                                          },
                                        ],
                                      },
                                      {
                                        kind: 'ObjectValue',
                                        fields: [
                                          {
                                            kind: 'ObjectField',
                                            name: { kind: 'Name', value: 'id' },
                                            value: {
                                              kind: 'StringValue',
                                              value: '1x_desktop',
                                              block: false,
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'width',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '468',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'height',
                                            },
                                            value: {
                                              kind: 'IntValue',
                                              value: '388',
                                            },
                                          },
                                          {
                                            kind: 'ObjectField',
                                            name: {
                                              kind: 'Name',
                                              value: 'mode',
                                            },
                                            value: {
                                              kind: 'EnumValue',
                                              value: 'AUTO',
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrResized' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'geo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrGeoRecommenderItem',
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'theme' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'fdrTheme' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webMeta' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'link' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'fdrLink' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrImageFragmentDoc.definitions,
    ...FdrResizedFragmentDoc.definitions,
    ...FdrGeoRecommenderItemFragmentDoc.definitions,
    ...FdrThemeFragmentDoc.definitions,
    ...FdrLinkFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrThemeGeoComponentsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrThemeGeoComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'components' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'fdrPaginatedThemeGeoRecommender',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginatedThemeGeoRecommenderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrThemeGeoComponentsQuery__
 *
 * To run a query within a React component, call `useFdrThemeGeoComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrThemeGeoComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrThemeGeoComponentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrThemeGeoComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrThemeGeoComponentsQuery,
    FdrThemeGeoComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrThemeGeoComponentsQuery,
    FdrThemeGeoComponentsQueryVariables
  >(FdrThemeGeoComponentsDocument, options);
}
export function useFdrThemeGeoComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrThemeGeoComponentsQuery,
    FdrThemeGeoComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrThemeGeoComponentsQuery,
    FdrThemeGeoComponentsQueryVariables
  >(FdrThemeGeoComponentsDocument, options);
}
export type FdrThemeGeoComponentsQueryHookResult = ReturnType<
  typeof useFdrThemeGeoComponentsQuery
>;
export type FdrThemeGeoComponentsLazyQueryHookResult = ReturnType<
  typeof useFdrThemeGeoComponentsLazyQuery
>;
export type FdrThemeGeoComponentsQueryResult = Apollo.QueryResult<
  FdrThemeGeoComponentsQuery,
  FdrThemeGeoComponentsQueryVariables
>;
