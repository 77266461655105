import React, { ReactElement } from 'react';
import { FdrImg } from '@hotelplan/fdr.regular.basis.fdr-img';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import { FdrMarketingMediaSwitch } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-marketing-media-switch';
import { getResourceLink } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-media.util';
import { FdrSingleMarketingItem } from 'components/domain/fdr-page-components/recommenders/marketing/fdr-single-marketing-item';
import FdrProductRecommenderSliderContent from 'components/domain/fdr-product-recommender/fdr-product-recommender-slider-content';
import { FdrTeaserCard } from 'components/domain/fdr-teaser-card';
import useLazyFetchPagination from 'components/domain/pagination/useLazyFetchPagination';
import {
  ListContainer,
  RecommendationsGrid,
} from 'components/domain/recommendations/Recommendations.common';
import { FdrMarketingRecommenderFragment } from 'schemas/fragment/regular/fdrMarketingRecommender.generated';

const ITEMS_PER_PAGE = 3;

interface IFdrMarketingRecommendationsProps {
  component: FdrMarketingRecommenderFragment;
}

enum EDisplayType {
  SWIPE = 'SWIPE',
  BLOCK = 'BLOCK',
}

function renderImageCard(item, lazy) {
  const media = item.media;
  const resourceLink = getResourceLink(item.media);

  if (media.__typename === 'FdrImage') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { resizedPreview, resizedImage, ...other } = media;
    const image = { resized: resizedPreview, ...other };

    return <FdrImg image={image} lazy={lazy} />;
  }

  return (
    <FdrMarketingMediaSwitch
      typename={media.__typename}
      resourceLink={resourceLink}
      lazy={lazy}
    />
  );
}

export function FdrMarketingRecommender({
  component,
}: IFdrMarketingRecommendationsProps): ReactElement {
  const {
    marketingItems,
    bgColor,
    fgColor,
    title,
    titleFgColor,
    displayType = EDisplayType.SWIPE,
  } = component;
  const { mobile } = useDeviceType();
  // Currently pagination is not delivered for marketing items from the backend
  const total = marketingItems.length;
  const perPage = ITEMS_PER_PAGE;

  const { items, page, prevPage, nextPage, setPage } = useLazyFetchPagination(
    marketingItems,
    {
      perPage,
      total,
    }
  );

  const itemsResult = marketingItems.map((item, index) => {
    const lazy = displayType === EDisplayType.SWIPE && index > 2;

    return (
      <FdrTeaserCard
        key={index}
        cfg={{
          subTitle: item.subtitle,
          title: item.title,
          lazy,
          link: item.link,
        }}
        img={renderImageCard(item, lazy)}
        data-id="marketing-card"
        className="marketing-item"
      />
    );
  });

  return (
    <div
      data-id="marketing-recommendations-section"
      style={{ color: fgColor, backgroundColor: bgColor }}
    >
      {total === 1 ? (
        <FdrSingleMarketingItem item={items[0]} />
      ) : (
        <ListContainer data-display={displayType}>
          {displayType !== EDisplayType.SWIPE ? (
            <CardsSection
              title={title}
              page={1}
              totalPages={1}
              headerStyle={{ color: titleFgColor, marginRight: '24px' }}
            >
              <RecommendationsGrid style={{ borderColor: titleFgColor }}>
                {itemsResult}
              </RecommendationsGrid>
            </CardsSection>
          ) : (
            <FdrProductRecommenderSliderContent
              name={title}
              total={total}
              page={page}
              perPage={perPage}
              prevPage={prevPage}
              nextPage={nextPage}
              setPage={setPage}
              fontColor={titleFgColor || fgColor}
              showTotal={false}
              isSliderPagination={!mobile}
            >
              {itemsResult}
            </FdrProductRecommenderSliderContent>
          )}
        </ListContainer>
      )}
    </div>
  );
}
