import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { RecommendationListContainer } from './Recommendations.styles';

export const ListContainer = styled(RecommendationListContainer)(
  ({ theme: { colors } }) => ({
    '.marketing-item': {
      '.teaser-media:after': {
        backgroundColor: 'transparent',
      },
    },
    '.teaser-media:after': {
      backgroundColor: colors.imageColorOverlay,
      mixBlendMode: 'multiply',
    },
  })
);

export const RecommendationsGrid = styled.div(
  sx2CssThemeFn({
    display: 'grid',
    gridTemplateColumns: ['1fr', '1fr 1fr 1fr'],
    '@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)':
      {
        gridTemplateColumns: '1fr 1fr',
      },
    '@media screen and (max-width: 896px) and (orientation: landscape)': {
      gridTemplateColumns: '1fr 1fr',
    },
    gap: '20px',
  })
);

const RecommenderLabel = styled.div(
  sx2CssThemeFn({
    ...cutTextByLinesCount(1),
    display: 'inline-block',
    marginRight: '8px',
    borderRadius: '5px',
    backgroundColor: 'paperGrey',
    py: 1,
    px: 2,
    fontSize: 1,
    textTransform: 'none',
    lineHeight: '22px',
    letterSpacing: '0.5px',
    fontWeight: '400',
  })
);

interface ICardItemBreadcrumbsProps<T> {
  className?: string;
  breadcrumbs: T[] | string[];
}

const RouteBreadcrumbs = styled.div(({ theme: { text } }) => text.groupHeading);

export function CardItemBreadcrumbs<T extends { name: string }>({
  className,
  breadcrumbs,
}: ICardItemBreadcrumbsProps<T>): ReactElement {
  if (!breadcrumbs.length) return null;

  return (
    <RouteBreadcrumbs className={className}>
      {breadcrumbs.map(item => {
        if (!item) return null;

        return (
          <RecommenderLabel key={item.name || item}>
            {item.name || item}
          </RecommenderLabel>
        );
      })}
    </RouteBreadcrumbs>
  );
}
