import React from 'react';
import { FdrTextBlock } from '@hotelplan/fdr.regular.basis.fdr-text-block';
import { FdrRawText } from '@hotelplan/fdr.regular.fusion.fdr-raw-text';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { FdrBackgroundWrapper } from 'components/domain/fdr-background-wrapper';
import {
  addGclidToJotformInHTMLText,
  useFdrGclid,
} from 'components/domain/fdr-jotform/fdr-jotform-gclid.utils';
import { RichTextContainer } from 'components/domain/fdr-page-components/fdr-page-components.styles';
import {
  FdrMarkdownTextFragment,
  FdrRawTextFragment,
} from 'schemas/fragment/regular/fdrText.generated';

interface IFdrTextPageComponentProps {
  component: FdrMarkdownTextFragment | FdrRawTextFragment;
  index: number;
}

const FdrTextPageComponent: React.FC<IFdrTextPageComponentProps> = ({
  component,
  index,
}) => {
  const { mobile } = useDeviceType();
  const gclid = useFdrGclid();

  const isRawText = component.__typename === 'FdrRawText';
  const topPadding = component.topPadding;
  const bottomPadding = component.bottomPadding;
  const textWidth = component.width;

  const imgUrl = component?.bgImage?.resized.filter(
    image => image.id === (mobile ? '1x_mobile' : '1x_desktop')
  )[0]?.link?.url;

  const textBlockWidth = textWidth === 'LARGE' ? 'large-text-width' : '';

  const textBlockBottomPadding =
    bottomPadding === 'NONE'
      ? 'none-padding-bottom'
      : bottomPadding === 'SMALL'
      ? 'small-padding-bottom'
      : '';

  const textBlockTopPadding =
    topPadding === 'NONE'
      ? 'none-padding-top'
      : topPadding === 'SMALL'
      ? 'small-padding-top'
      : '';

  return (
    <FdrBackgroundWrapper
      image={imgUrl}
      bgColor={component?.bgColor}
      fontColor={component?.fgColor}
    >
      <RichTextContainer
        className={`${textBlockBottomPadding} ${textBlockTopPadding} anchor-section`}
      >
        {isRawText ? (
          <FdrRawText
            uid={`raw-text-${index}`}
            text={addGclidToJotformInHTMLText(component?.text || '', gclid)}
            className={`raw-text ${textBlockWidth}`}
          />
        ) : (
          <FdrTextBlock
            text={component?.text}
            className={`markdown-component ${textBlockWidth}`}
          />
        )}
      </RichTextContainer>
    </FdrBackgroundWrapper>
  );
};

export default FdrTextPageComponent;
