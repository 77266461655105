import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const BenefitItemContainer = styled.div(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    overflowX: 'auto',
    columnGap: ['0px', '20px'],
    rowGap: ['40px', '0px'],
    flexDirection: ['column', 'row'],

    '.with-link': { '&:hover': { borderColor: colors.primary } },
    '&::-webkit-scrollbar': { display: 'none' },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
  })
);

export const BenefitItem = styled.div(
  sx2CssThemeFn({
    backgroundColor: 'white',
    padding: ['20px 30px 20px 30px', '22px 20px 20px 20px'],
    border: 'none',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,

    width: ['100%', '25%'],
    minHeight: ['86px', '191px'],
    fontSize: '18px',
    lineHeight: 1.44,

    '&:not(:last-child)::after': {
      content: "''",
      backgroundImage:
        'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB9SURBVHgBjZDBDYAgDEWpgbsjdAES2UA3cRRHcCPZgAMMwAjeOeA/YGIIir20fX1pk5L3ftZaW/ESzrlRKcU55/GPRyGEjD6mlBZjTKwkhnSg5IK63lAYSynX+mph/EBd714oiCjWItjZYJ8e4YcW2eI/m2gE5jvSVNqudwHwYzZEEcfCzwAAAABJRU5ErkJggg==")',
      display: 'block',
      position: 'absolute',
      height: '6px',
      top: ['calc(100% + 17.5px)', '50%'],
      left: ['calc(50% - 20px)', '100%'],
      transform: [`rotate(90deg)`, `rotate(0deg)`],
      width: ['40px', '20px'],
    },
  })
);

export const BenefitItemNumber = styled.div(
  sx2CssThemeFn({
    fontSize: ['18px', '24px'],
    color: 'redBurgundy',
    fontWeight: '700',
  })
);

export const BenefitItemText = styled.div({
  textAlign: 'left',
  marginLeft: '20px',
});
