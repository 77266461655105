import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { Container } from 'components/domain/container';
import {
  GridRecommendationBlock,
  GridRecommendationItems,
  GridRecommendationSectionMainTitle,
} from './CommonGridRecommendationSection.styles';
import { LoadMoreButtonWithWrapper } from './LoadMoreButton';

interface ICommonGridRecommendationSectionProps<T> {
  items: T[];
  recommendationItem: React.FC<PropsWithChildren<{ item: T }>>;
  nextPage(): void;
  hasMore: boolean;
  loading?: boolean;
  id?: string;
  mainTitle?: string;
  loadMoreText?: string;
  fontColor?: string;
  bgColor?: string;
  testId?: string;
}

function CommonGridRecommendationSection<T>(
  props: PropsWithChildren<ICommonGridRecommendationSectionProps<T>>
): ReactElement {
  const {
    items,
    recommendationItem: RecommendationItem,
    nextPage,
    hasMore,
    loading,
    id,
    mainTitle,
    loadMoreText,
    fontColor,
    bgColor,
    testId,
    children,
  } = props;
  const [t] = useTranslation('common');

  return (
    <GridRecommendationBlock
      fontColor={fontColor}
      bgColor={bgColor}
      data-id={testId}
      className="geo-recommender"
      id={id}
    >
      <Container>
        <GridRecommendationSectionMainTitle>
          {mainTitle}
        </GridRecommendationSectionMainTitle>
        {children}
        <GridRecommendationItems>
          {items.map((item, index) => {
            return <RecommendationItem item={item} key={index} />;
          })}
        </GridRecommendationItems>
        {hasMore && !loading ? (
          <LoadMoreButtonWithWrapper onClick={nextPage}>
            {loadMoreText ? loadMoreText : t('common:load.more')}
          </LoadMoreButtonWithWrapper>
        ) : null}
      </Container>
    </GridRecommendationBlock>
  );
}

export default CommonGridRecommendationSection;
