import React, { PropsWithChildren } from 'react';
import {
  AdaptiveCardsSlider,
  CardsSection,
} from 'components/domain/customizable-slider/CardsSection';
import { searchCardPlaceholder } from 'components/domain/fdr-search/fdr-search-section.skeleton';
import { CARDS_GAP } from 'components/domain/fdr-search/fdr-search.constants';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';

interface IFdrProductRecommenderContainerProps {
  total: number;
  page: number;
  perPage: number;
  nextPage(): void;
  prevPage(): void;
  setPage(page: number): void;
  name?: string;
  fontColor?: string;
  sectionTitle?: string;
  loading?: boolean;
  customContent?: React.ReactNode;
  showTotal?: boolean;
  imageHeight?: number;
  isSliderPagination?: boolean;
}

const FdrProductRecommenderSliderContent: React.FC<
  PropsWithChildren<IFdrProductRecommenderContainerProps>
> = ({
  page,
  perPage,
  nextPage,
  prevPage,
  setPage,
  loading,
  sectionTitle,
  name,
  fontColor,
  total,
  customContent,
  showTotal = true,
  imageHeight,
  isSliderPagination,
  children,
}) => {
  async function onChangePage(newPage: number) {
    setPage(newPage);
  }

  return (
    <CardsSection
      title={name}
      totalCount={showTotal && total}
      page={page}
      totalPages={Math.ceil(total / perPage)}
      onNextPage={nextPage}
      onPrevPage={prevPage}
      paginationLoading={loading}
      isSliderPagination={isSliderPagination}
      trackingSource={PaginationArrowClickSource.SRL}
      headerStyle={{ color: fontColor }}
      sectionTitle={sectionTitle}
      imageHeight={imageHeight}
    >
      {customContent ? <>{customContent}</> : null}
      <AdaptiveCardsSlider
        hideDelimiter
        page={page}
        total={total}
        perPage={perPage}
        onChangePage={onChangePage}
        placeholder={searchCardPlaceholder}
        cardsGap={CARDS_GAP}
      >
        {children}
      </AdaptiveCardsSlider>
    </CardsSection>
  );
};

export default FdrProductRecommenderSliderContent;
