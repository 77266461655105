import { IMediaGalleryItem } from '@hotelplan/core.serving.bs-rsv-gallery';
import { FdrCoordinates, FdrImage, FdrMedia } from '@hotelplan/supergraph-api';
import { FdrMediaImageWithPreview } from 'components/domain/fdr-media/fdr-media.types';
import { createMediaImageProps } from 'components/domain/fdr-media/fdr-media.utils';

const RESIZED_FIELD_NAME = 'resized';
const RESIZED_PREVIEW_FIELD_NAME = 'resizedPreview';

type FdrMediaWithPreviewImage = FdrMedia extends infer T
  ? T extends FdrImage
    ? FdrMediaImageWithPreview<'resized', 'resizedPreview'>
    : T
  : never;

export function mapFdrMediaToImageMediaItems(
  mediaItems: FdrMediaWithPreviewImage[]
): (IMediaGalleryItem & { coordinates?: FdrCoordinates })[] {
  if (!mediaItems) return [];

  return mediaItems.map(mediaItem => {
    if (mediaItem.__typename !== 'FdrImage') return null;

    const fdrImage = mediaItem;

    return {
      __typename: `ImageMediaItem`,
      image: createMediaImageProps(fdrImage, RESIZED_FIELD_NAME),
      resource: fdrImage.rawUrl,
      preview: fdrImage.resizedPreview
        ? createMediaImageProps(fdrImage, RESIZED_PREVIEW_FIELD_NAME)
        : createMediaImageProps(fdrImage, RESIZED_FIELD_NAME),
      title: fdrImage.alt,
      coordinates: fdrImage.coordinates,
    };
  });
}
