import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { CardsSection } from 'components/domain/customizable-slider/CardsSection';
import { AdaptiveCardsSlider } from 'components/domain/customizable-slider/ThemeCardsSection';
import { PaginationArrowClickSource } from 'components/domain/tracking/paginationTracking';

interface ISliderSectionProps {
  total: number;
  page: number;
  perPage: number;
  prevPage(): void;
  nextPage(): void;
  setPage(page: number): void;
  title?: string;
  titleColor?: string;
  itemsPerPage?: number;
  className?: string;
  customContent?: React.ReactNode;
}

export function FdrThemeRecommendationSlider({
  total,
  page,
  perPage,
  prevPage,
  nextPage,
  setPage,
  title,
  titleColor,
  className,
  children,
  customContent,
}: PropsWithChildren<ISliderSectionProps>): React.ReactElement {
  const { mobile } = useDeviceType();
  const isSliderPagination = !mobile;
  const { t } = useTranslation();
  const totalPages = Math.ceil(total / perPage);

  async function onChangePage(newPage: number) {
    setPage(newPage);
  }

  return (
    <CardsSection
      className={className}
      title={title}
      page={page}
      totalPages={totalPages}
      sectionTitle={
        isSliderPagination &&
        totalPages > 1 &&
        t('theme.recommender.section.title')
      }
      totalCount={isSliderPagination && totalPages > 1 && total}
      onPrevPage={prevPage}
      onNextPage={nextPage}
      headerStyle={{ color: titleColor }}
      isSliderPagination={isSliderPagination}
      trackingSource={PaginationArrowClickSource.THEME}
    >
      {customContent ? <>{customContent}</> : null}
      <AdaptiveCardsSlider
        page={page}
        onChangePage={onChangePage}
        perPage={perPage}
        total={total}
        isSliderPagination={isSliderPagination}
      >
        {children}
      </AdaptiveCardsSlider>
    </CardsSection>
  );
}
