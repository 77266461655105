import { QueryResult } from '@apollo/client';
import React from 'react';
import { IFdrImageProps } from '@hotelplan/fdr.regular.basis.fdr-image';
import { TFdrMediaMetaMap } from '@hotelplan/fdr.regular.basis.fdr-picture';
import FdrResponsiveImgWithCopyright from 'components/domain/fdr-copyright/fdr-responsive-img-with-copyright';
import {
  isFdrThGeoObject,
  mapProductFeatureGroupsToProductFeatures,
} from 'components/domain/fdr-geo/fdr-geo.utils';
import {
  isFdrAdventureTravelProduct,
  isFdrHotelProduct,
  isFdrThProduct,
} from 'components/domain/fdr-product/fdr-product.utils';
import { FdrBlogArticleFragment } from 'schemas/fragment/blog-articles/fdr-all-blog-articles.generated';
import { FdrAgencyItemFragment } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import { FdrGeoRecommenderFragment } from 'schemas/fragment/recommenders/geo/fdr-geo-recommender.generated';
import { FdrThemeGeoRecommenderFragment } from 'schemas/fragment/recommenders/theme-geo/fdr-theme-geo-recommender.generated';
import { FdrThemeRecommenderFragment } from 'schemas/fragment/recommenders/theme/fdr-theme-recommender.generated';
import {
  FdrAgencyComponentsQuery,
  FdrAgencyComponentsQueryVariables,
  FdrPaginatedAgencyRecommenderFragment,
} from 'schemas/query/agencies/fdr-paginated-agency-recommender.generated';
import {
  FdrBlogArticleComponentsQuery,
  FdrBlogArticleComponentsQueryVariables,
  FdrPaginatedBlogArticleRecommenderFragment,
} from 'schemas/query/blog-article/fdr-paginated-blog-recommender.generated';
import {
  FdrPaginatedGeoRecommenderQuery,
  FdrPaginatedGeoRecommenderQueryVariables,
} from 'schemas/query/geo/fdr-paginated-geo-recommender.generated';
import {
  FdrPaginatedProductRecommenderFragment,
  FdrProductComponentsQuery,
  FdrProductComponentsQueryVariables,
} from 'schemas/query/product/fdr-paginated-product-recommender.generated';
import {
  FdrThemeGeoComponentsQuery,
  FdrThemeGeoComponentsQueryVariables,
} from 'schemas/query/theme-geo/fdr-paginated-theme-geo-recommender.generated';
import {
  FdrThemeComponentsQuery,
  FdrThemeComponentsQueryVariables,
} from 'schemas/query/theme/fdr-paginated-theme-recommender.generated';
import { FdrImageMediaWrapper } from './fdr-page-components.styles';
import {
  TFdrProductRecommenderComponent,
  TFdrProductRecommenderProduct,
  TCommonRecommenderItem,
} from './fdr-page-components.types';
import { IFdrRecommenderProduct } from './recommenders/product/fdr-product-recommender.types';

export function getFdrProductRecommenderProducts(
  component: TFdrProductRecommenderComponent
): TFdrProductRecommenderProduct[] {
  return component.productPage?.products?.filter(isFdrThProduct) || [];
}

export function renderResponsiveImg(
  className: string,
  specs: TFdrMediaMetaMap
) {
  return function img(props: IFdrImageProps & { key: React.Key }): JSX.Element {
    const { key, ...imageProps } = props;

    return (
      <FdrImageMediaWrapper className="hero-media-wrapper" key={key}>
        <FdrResponsiveImgWithCopyright
          {...imageProps}
          imageProps={{
            className,
            noSet: true,
          }}
          specs={specs}
        />
      </FdrImageMediaWrapper>
    );
  };
}

export function createPageComponentVariables(
  id: string,
  page: number,
  perPage: number
) {
  const input = { ids: [id] };
  const pagination = { page, perPage };

  return {
    input,
    pagination,
  };
}

export function mapFdrPageComponentsResponseToProducts(
  data: QueryResult<
    FdrProductComponentsQuery,
    FdrProductComponentsQueryVariables
  >['data']
): IFdrRecommenderProduct[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrProductRecommender'
  ) as FdrPaginatedProductRecommenderFragment;

  return recommender?.productPage?.products?.map?.(mapRecommenderProduct) || [];
}

export function mapRecommenderProduct(product: TFdrProductRecommenderProduct) {
  return {
    ...product,
    link: product.webMeta.descriptionWebMeta.link,
    image: product.images[0],
    features: isFdrHotelProduct(product)
      ? []
      : mapProductFeatureGroupsToProductFeatures(product.featureGroups),
    route: isFdrAdventureTravelProduct(product)
      ? product.route?.map(r => r.locationName)
      : [],
  };
}

export function mapFdrPageComponentsResponseToAgencies(
  data: QueryResult<
    FdrAgencyComponentsQuery,
    FdrAgencyComponentsQueryVariables
  >['data']
): FdrAgencyItemFragment[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrAgencyRecommender'
  ) as FdrPaginatedAgencyRecommenderFragment;

  return recommender?.agencies?.agenciesPage || [];
}

export function mapFdrPageComponentsResponseToBlogArticles(
  data: QueryResult<
    FdrBlogArticleComponentsQuery,
    FdrBlogArticleComponentsQueryVariables
  >['data']
): FdrBlogArticleFragment[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrBlogArticleRecommender'
  ) as FdrPaginatedBlogArticleRecommenderFragment;

  return recommender?.blogArticleItems?.blogArticles || [];
}

export function mapFdrPageComponentsResponseToGeo(
  data: QueryResult<
    FdrPaginatedGeoRecommenderQuery,
    FdrPaginatedGeoRecommenderQueryVariables
  >['data']
): TCommonRecommenderItem[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrGeoRecommender'
  ) as FdrGeoRecommenderFragment;

  return recommender?.itemsPage.items.filter(isFdrThGeoObject).map(i => ({
    ...i,
    link: i.webMeta.descriptionWebMeta?.link,
  }));
}

export function mapFdrPageComponentsResponseToThemes(
  data: QueryResult<
    FdrThemeComponentsQuery,
    FdrThemeComponentsQueryVariables
  >['data']
): TCommonRecommenderItem[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrThemeRecommender'
  ) as FdrThemeRecommenderFragment;

  return (
    recommender?.themePage.themes?.map(i => ({
      ...i,
      link: i.webMeta.descriptionWebMeta?.link,
    })) || []
  );
}

export function mapFdrPageComponentsResponseToThemeGeo(
  data: QueryResult<
    FdrThemeGeoComponentsQuery,
    FdrThemeGeoComponentsQueryVariables
  >['data']
): TCommonRecommenderItem[] {
  const recommender = data?.fdrComponents?.components?.find(
    comp => comp.__typename === 'FdrThemeGeoRecommender'
  ) as FdrThemeGeoRecommenderFragment;

  return (
    recommender?.itemsPage.items?.map(i => ({
      ...i,
      id: [i.geo.id, i.theme.id].join('-'),
      link: i.webMeta.link,
    })) || []
  );
}
