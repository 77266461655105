import { useTranslation } from 'next-i18next';
import React, { useCallback, useState } from 'react';
import { EPageType } from '@hotelplan/fdr.lib.page.available-pages';
import { usePageType } from '@hotelplan/libs.context.page-type';
import FdrProductRecommenderSliderContent from 'components/domain/fdr-product-recommender/fdr-product-recommender-slider-content';
import useLazyFetchPagination from 'components/domain/pagination/useLazyFetchPagination';
import { RecommendationListContainer } from 'components/domain/recommendations/Recommendations.styles';
import { FdrAgencyItemFragment } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import { IAgencyRecommendationProps } from './fdr-agency-recommender.types';
import {
  FdrAgencyOverviewItem,
  FdrFlexibleAgencyItem,
} from './fdr-flexible-agency-item';

const AGENCIES_PER_PAGE = 3;

export function AgencyRecommenderContent({
  total,
  agencyMapSearch,
  loading,
  fetchItems,
  ...recommenderComponent
}: IAgencyRecommendationProps) {
  const [t] = useTranslation('agency');
  const pageType = usePageType<EPageType>();
  const perPage = AGENCIES_PER_PAGE;

  const { fgColor, bgColor, agencies, title } = recommenderComponent;

  const { items, page, prevPage, nextPage, setPage } =
    useLazyFetchPagination<FdrAgencyItemFragment>(agencies, {
      total,
      perPage,
      fetchItems,
    });

  const [imageHeight, setImageHeight] = useState<number>();

  const updateImageHeight = useCallback((height: number) => {
    if (height) {
      setImageHeight(height);
    }
  }, []);

  const FdrAgencyItem =
    pageType === EPageType.AgencyOverview
      ? FdrAgencyOverviewItem
      : FdrFlexibleAgencyItem;

  return (
    <div
      style={{ color: fgColor, backgroundColor: bgColor }}
      data-id="agency-recommendations-section"
    >
      <RecommendationListContainer>
        <FdrProductRecommenderSliderContent
          name={title || t('agency:agencies.title')}
          total={total}
          page={page}
          perPage={perPage}
          nextPage={nextPage}
          prevPage={prevPage}
          setPage={setPage}
          loading={loading}
          fontColor={fgColor}
          customContent={agencyMapSearch}
          showTotal={false}
          imageHeight={imageHeight}
          isSliderPagination={pageType !== EPageType.AgencyOverview}
        >
          {items.map((agency, i) => {
            return (
              <FdrAgencyItem
                key={`agency-recommendation-item-${i}`}
                agency={agency}
                updateImageHeight={updateImageHeight}
              />
            );
          })}
        </FdrProductRecommenderSliderContent>
      </RecommendationListContainer>
      <div></div>
    </div>
  );
}
