import React, { useCallback } from 'react';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToThemes,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrThemeComponentsDocument } from 'schemas/query/theme/fdr-paginated-theme-recommender.generated';
import { FdrThemeRecommender } from './fdr-theme-recommender';

interface IFdrThemePageComponentProps {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrThemeRecommender' }
  >;
}

const FdrThemePageComponent: React.FC<IFdrThemePageComponentProps> = ({
  component,
}) => {
  const mapVariables = useCallback(
    (page, perPage) => {
      return createPageComponentVariables(component.id, page, perPage);
    },
    [component.id]
  );

  const { fetchItems } = useFdrPageComponentsFetch(
    FdrThemeComponentsDocument,
    mapVariables,
    mapFdrPageComponentsResponseToThemes
  );

  return (
    <FdrThemeRecommender
      total={component.themePage.pagination.total}
      title={component.title}
      id={component.id}
      bgColor={component.bgColor}
      fontColor={component.fgColor}
      items={component.themePage.themes?.map(theme => ({
        ...theme,
        link: theme.webMeta.descriptionWebMeta.link,
      }))}
      displayType={component.displayType}
      fetchItems={fetchItems}
    />
  );
};

export default FdrThemePageComponent;
