import { DocumentNode } from 'graphql';
import { useCallback } from 'react';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
import { IFetchComponentResponse } from './fdr-page-components.types';

const useFdrPageComponentsFetch = <TItem, TData, TVariables>(
  queryDocument: DocumentNode,
  mapVariables: (page: number, perPage: number) => TVariables,
  mapData: (data: TData) => TItem[]
) => {
  const [query, { loading }] = ApolloReactHooks.useLazyQuery<TData, TVariables>(
    queryDocument,
    {}
  );

  const fetchItems = useCallback(
    async (
      page: number,
      perPage: number
    ): Promise<IFetchComponentResponse<TItem>> => {
      const { data } = await query({
        variables: mapVariables(page, perPage),
      });

      return {
        items: mapData(data),
        pagination: {
          page,
          perPage,
        },
      };
    },
    [mapData, mapVariables, query]
  );

  return {
    fetchItems,
    loading,
  };
};

export default useFdrPageComponentsFetch;
