import { useTranslation } from 'next-i18next';
import React, { PropsWithChildren, ReactElement } from 'react';
import { FdrPagination } from '@hotelplan/supergraph-api';
import {
  IFetchComponentResponse,
  TCommonRecommenderItem,
} from 'components/domain/fdr-page-components/fdr-page-components.types';
import useLazyFetchPagination from 'components/domain/pagination/useLazyFetchPagination';
import { LoadMoreButtonWithWrapper } from 'components/domain/recommendations/LoadMoreButton';
import { FdrCommonGridRecommenderUnpaginated } from './fdr-common-grid-recommender-unpaginated';

interface IFdrCommonGridRecommenderProps {
  items: Array<TCommonRecommenderItem>;
  recommendationItem: React.FC<
    PropsWithChildren<{ item: TCommonRecommenderItem }>
  >;
  loadMoreText?: string;
  bgColor?: string;
  fontColor?: string;
  title?: string;
  pagination?: FdrPagination;
  fetchItems?: (
    page: number,
    perPage: number
  ) => Promise<IFetchComponentResponse<TCommonRecommenderItem>>;
}

export function FdrCommonGridRecommender(
  props: IFdrCommonGridRecommenderProps
): ReactElement {
  const {
    recommendationItem,
    loadMoreText,
    pagination,
    items: geoItems,
    title,
    fetchItems,
  } = props;
  const [t] = useTranslation('common');

  const { items, hasMore, nextPage } = useLazyFetchPagination(geoItems, {
    total: pagination.total,
    perPage: pagination.perPage,
    fetchItems,
  });

  return (
    <FdrCommonGridRecommenderUnpaginated
      title={title}
      items={items}
      recommendationItem={recommendationItem}
    >
      {hasMore ? (
        <LoadMoreButtonWithWrapper onClick={nextPage}>
          {loadMoreText ? loadMoreText : t('common:load.more')}
        </LoadMoreButtonWithWrapper>
      ) : null}
    </FdrCommonGridRecommenderUnpaginated>
  );
}
