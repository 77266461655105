import noop from 'lodash/noop';
import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import FdrProductRecommenderSliderContent from 'components/domain/fdr-product-recommender/fdr-product-recommender-slider-content';
import { RecommendationListContainer } from 'components/domain/recommendations/Recommendations.styles';
import { FdrAgencyItemPlaceholder } from './fdr-flexible-agency-item';

const AGENCIES_PLACEHOLDER_COUNT = 3;

interface IAgencyRecommenderContentSkeletonProps {
  agencyMapSearch?: ReactNode;
  title?: string;
  fgColor?: string;
  bgColor?: string;
}

export function AgencyRecommenderContentSkeleton({
  agencyMapSearch,
  fgColor,
  bgColor,
  title,
}: IAgencyRecommenderContentSkeletonProps) {
  const [t] = useTranslation('agency');
  const { mobile } = useDeviceType();
  const count = AGENCIES_PLACEHOLDER_COUNT;

  const renderedAgencies = Array.from({ length: count }).map((_, index) => (
    <FdrAgencyItemPlaceholder key={`agency-card-${index}`} />
  ));

  return (
    <div
      style={{ color: fgColor, backgroundColor: bgColor }}
      data-id="agency-recommendations-section"
    >
      <RecommendationListContainer>
        <FdrProductRecommenderSliderContent
          name={title || t('agency:agencies.title')}
          total={count}
          page={0}
          perPage={count}
          nextPage={noop}
          prevPage={noop}
          setPage={noop}
          loading={true}
          fontColor={fgColor}
          customContent={agencyMapSearch}
          showTotal={false}
          isSliderPagination={!mobile}
        >
          {renderedAgencies}
        </FdrProductRecommenderSliderContent>
      </RecommendationListContainer>
    </div>
  );
}
