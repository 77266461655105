import type * as Types from '@hotelplan/supergraph-api';

import type { DocumentNode } from 'graphql';
import { FdrPaginationFragmentDoc } from 'schemas/fragment/regular/fdrPagination.generated';
import { FdrGeoRecommenderItemFragmentDoc } from 'schemas/fragment/recommenders/geo-recommender-item/fdr-geo-recommender-item.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/fdr.lib.apollo.query';
const defaultOptions = {} as const;
export type FdrPaginatedGeoRecommenderQueryVariables = Types.Exact<{
  input: Types.FdrComponentsInput;
  pagination: Types.FdrPaginationInput;
}>;

export type FdrPaginatedGeoRecommenderQuery = {
  __typename?: 'Query';
  fdrComponents: {
    __typename?: 'FdrComponentsResponse';
    components: Array<
      | { __typename?: 'FdrAccordion' }
      | { __typename?: 'FdrAccordionComponent' }
      | { __typename?: 'FdrAgencyContactModule' }
      | { __typename?: 'FdrAgencyRecommender' }
      | { __typename?: 'FdrBenefitsComponent' }
      | { __typename?: 'FdrBlogArticleRecommender' }
      | { __typename?: 'FdrBrandsComponent' }
      | { __typename?: 'FdrContactModule' }
      | { __typename?: 'FdrEmployeesComponent' }
      | { __typename?: 'FdrFaq' }
      | { __typename?: 'FdrFlightOfferRecommender' }
      | { __typename?: 'FdrFlightRecommender' }
      | { __typename?: 'FdrGeoChildrenPlaceholder' }
      | { __typename?: 'FdrGeoClimatePlaceholder' }
      | { __typename?: 'FdrGeoGalleryPlaceholder' }
      | { __typename?: 'FdrGeoLeadTextPlaceholder' }
      | { __typename?: 'FdrGeoLongTextPlaceholder' }
      | { __typename?: 'FdrGeoMapPlaceholder' }
      | { __typename?: 'FdrGeoOfferButtonPlaceholder' }
      | { __typename?: 'FdrGeoOfferRecommender' }
      | {
          __typename?: 'FdrGeoRecommender';
          id: string;
          title?: string | null;
          fgColor?: string | null;
          bgColor?: string | null;
          itemsPage: {
            __typename?: 'FdrGeoRecommenderItem';
            pagination: {
              __typename?: 'FdrPagination';
              total: number;
              page: number;
              perPage: number;
            };
            items?: Array<
              | {
                  __typename: 'FdrContinent';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrCountry';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrCountryGroup';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrDestination';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrRegion';
                  id: string;
                  title: string;
                  webMeta: {
                    __typename?: 'FdrGeoWebMeta';
                    descriptionWebMeta?: {
                      __typename?: 'FdrMeta';
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    } | null;
                  };
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
              | {
                  __typename: 'FdrResort';
                  id: string;
                  title: string;
                  image?: {
                    __typename?: 'FdrImage';
                    alt: string;
                    description?: string | null;
                    copyright?: string | null;
                    rawUrl: string;
                    resized: Array<{
                      __typename?: 'FdrResizedImage';
                      id: string;
                      link: {
                        __typename?: 'FdrLink';
                        openMethod?: Types.FdrLinkOpenMethod | null;
                        legacyUrl?: string | null;
                        type: Types.FdrLinkType;
                        url: string;
                      };
                    }>;
                  } | null;
                }
            > | null;
          };
        }
      | { __typename?: 'FdrGeoRecommenderPlaceholder' }
      | { __typename?: 'FdrHeroMediaGallery' }
      | { __typename?: 'FdrImageText' }
      | { __typename?: 'FdrLinkList' }
      | { __typename?: 'FdrMarkdownText' }
      | { __typename?: 'FdrMarketingRecommender' }
      | { __typename?: 'FdrMarketingTeaser' }
      | { __typename?: 'FdrMediaGallery' }
      | { __typename?: 'FdrNewsArticlesRecommender' }
      | { __typename?: 'FdrNewsletterSignup' }
      | { __typename?: 'FdrProductOfferRecommender' }
      | { __typename?: 'FdrProductOffersMap' }
      | { __typename?: 'FdrProductRecommender' }
      | { __typename?: 'FdrRawText' }
      | { __typename?: 'FdrRssBlogArticleRecommender' }
      | { __typename?: 'FdrSmartSeerRecommender' }
      | { __typename?: 'FdrSrlButton' }
      | { __typename?: 'FdrThemeGeoRecommender' }
      | { __typename?: 'FdrThemeOfferRecommender' }
      | { __typename?: 'FdrThemeRecommender' }
      | { __typename?: 'FdrUspBoxesComponent' }
      | null
    >;
  };
};

export type FdrPaginatedGeoRecommenderFragment = {
  __typename?: 'FdrGeoRecommender';
  id: string;
  title?: string | null;
  fgColor?: string | null;
  bgColor?: string | null;
  itemsPage: {
    __typename?: 'FdrGeoRecommenderItem';
    pagination: {
      __typename?: 'FdrPagination';
      total: number;
      page: number;
      perPage: number;
    };
    items?: Array<
      | {
          __typename: 'FdrContinent';
          id: string;
          title: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrCountry';
          id: string;
          title: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrCountryGroup';
          id: string;
          title: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrDestination';
          id: string;
          title: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrRegion';
          id: string;
          title: string;
          webMeta: {
            __typename?: 'FdrGeoWebMeta';
            descriptionWebMeta?: {
              __typename?: 'FdrMeta';
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            } | null;
          };
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
      | {
          __typename: 'FdrResort';
          id: string;
          title: string;
          image?: {
            __typename?: 'FdrImage';
            alt: string;
            description?: string | null;
            copyright?: string | null;
            rawUrl: string;
            resized: Array<{
              __typename?: 'FdrResizedImage';
              id: string;
              link: {
                __typename?: 'FdrLink';
                openMethod?: Types.FdrLinkOpenMethod | null;
                legacyUrl?: string | null;
                type: Types.FdrLinkType;
                url: string;
              };
            }>;
          } | null;
        }
    > | null;
  };
};

export const FdrPaginatedGeoRecommenderFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'fdrPaginatedGeoRecommender' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'FdrGeoRecommender' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fgColor' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bgColor' } },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'itemsPage' },
            name: { kind: 'Name', value: 'geoRecommenderItems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'pagination' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'pagination' },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pagination' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrPagination' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  alias: { kind: 'Name', value: 'items' },
                  name: { kind: 'Name', value: 'geo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'fdrGeoRecommenderItem' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginationFragmentDoc.definitions,
    ...FdrGeoRecommenderItemFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;
export const FdrPaginatedGeoRecommenderDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FdrPaginatedGeoRecommender' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrComponentsInput' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pagination' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'FdrPaginationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fdrComponents' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'FdrComponentsResponse' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'components' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'fdrPaginatedGeoRecommender',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...FdrPaginatedGeoRecommenderFragmentDoc.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useFdrPaginatedGeoRecommenderQuery__
 *
 * To run a query within a React component, call `useFdrPaginatedGeoRecommenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useFdrPaginatedGeoRecommenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFdrPaginatedGeoRecommenderQuery({
 *   variables: {
 *      input: // value for 'input'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useFdrPaginatedGeoRecommenderQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FdrPaginatedGeoRecommenderQuery,
    FdrPaginatedGeoRecommenderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FdrPaginatedGeoRecommenderQuery,
    FdrPaginatedGeoRecommenderQueryVariables
  >(FdrPaginatedGeoRecommenderDocument, options);
}
export function useFdrPaginatedGeoRecommenderLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FdrPaginatedGeoRecommenderQuery,
    FdrPaginatedGeoRecommenderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FdrPaginatedGeoRecommenderQuery,
    FdrPaginatedGeoRecommenderQueryVariables
  >(FdrPaginatedGeoRecommenderDocument, options);
}
export type FdrPaginatedGeoRecommenderQueryHookResult = ReturnType<
  typeof useFdrPaginatedGeoRecommenderQuery
>;
export type FdrPaginatedGeoRecommenderLazyQueryHookResult = ReturnType<
  typeof useFdrPaginatedGeoRecommenderLazyQuery
>;
export type FdrPaginatedGeoRecommenderQueryResult = Apollo.QueryResult<
  FdrPaginatedGeoRecommenderQuery,
  FdrPaginatedGeoRecommenderQueryVariables
>;
