import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { Container } from 'components/domain/container';

export const FdrMediaGalleryContainer = styled(Container)(
  sx2CssThemeFn({
    my: ['30px', '70px'],
    px: [3, '20px'],
    '.open-preview': {
      mr: [3, '40px'],
      mb: 3,
      '.icon': {
        width: '32px',
        height: '32px',
      },
    },
    '> div': {
      maxHeight: '640px',
    },
    '.slide-title': {
      maxWidth: '940px',
    },
  })
);

export const FdrHeroMediaContent = styled(Container)(
  sx2CssThemeFn({
    position: 'absolute',
    zIndex: 1,
    top: ['220px', '191px'],
    margin: '0 auto',
    left: '0',
    right: '0',
    width: '100%',
    maxWidth: ['none', '1480px'],
    p: [0, '0 20px'],
    '&.is-home': {
      top: ['40%', '50%'],
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 'none',
      mt: 0,
      ml: 0,
    },
    '.site-tools-button-wrapper': {
      '.icon': {
        width: '27px',
        height: '27px',
        color: 'white',
        fill: 'white',
        ml: 0,
        marginTop: '0',
        transform: 'none',
      },
      button: {
        ':hover': {
          border: 'none',
          '.icon': {
            color: 'white',
          },
        },
      },
    },
    '&.not-home': {
      '.site-tools-button-wrapper, .site-tools-button-wrapper-bookmark': {
        position: 'relative',
        width: '42px',
        height: '42px',
        border: '1px solid white',
        borderRadius: '5px',
        '> .icon, > button > .icon ': {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '20px',
          height: '20px',
          color: 'white',
          fill: 'transparent',
          margin: '0',
        },
        '.button-text': {
          position: 'absolute',
          top: '53px',
          width: 'max-content',
          textShadow: 'none',
          '&:first-letter': {
            textTransform: `capitalize`,
          },
        },
        '&:hover': {
          backgroundColor: 'paperGrey',
          borderColor: 'redHover',
          '> .icon, > button > .icon': {
            color: 'redHover',
          },
          '.button-text': {
            display: 'block',
            color: 'black',
            fontWeight: '400',
            fontSize: 1,
            lineHeight: '22px',
            letterSpacing: '0.5px',
            backgroundColor: 'paperGrey',
            boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)',
            borderRadius: '5px',
            padding: '5px 10px',
            left: 'auto',
            right: '0',
            transform: 'none',
            zIndex: '110',
            '&:before': {
              content: '""',
              position: 'absolute',
              width: '16px',
              height: '16px',
              left: 'auto',
              right: ['4px', 'none'],
              top: '-8px',
              backgroundColor: 'paperGrey',
              transform: 'translateX(-50%)  rotate(-45deg)',
              zIndex: '1',
            },
          },
        },
        '&:active': {
          backgroundColor: 'white',
          borderColor: 'redActive',
          '> .icon, > button > .icon': {
            color: 'redActive',
          },
        },
      },
      '.site-tools-button-wrapper-bookmark > button': {
        width: '100%',
        height: '100%',
      },
    },
  })
);
