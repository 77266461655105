import styled from 'styled-components';
import { cutTextByLinesCount } from '@hotelplan/components.common.mixins';
import { SlidersNavigationContainer } from 'components/domain/container';

export const ProductRecommendationWrapper = styled.div(
  ({ theme: { text, colors, space, media, fontSizes } }) => ({
    '.image-card': {
      marginBottom: space[3],
    },
    '.route-list, .teaser-text': {
      marginBottom: space[2],
    },
    '.route-list': cutTextByLinesCount(1),
    '.teaser-text': {
      ...text.copyText,
      ...cutTextByLinesCount(3),
      maxHeight: '80px',
    },
    '.heading-wrap': {
      marginBottom: '12px',
      '.icon': {
        color: colors.primary,
        marginLeft: space[2],
        width: '23px',
        verticalAlign: 'text-bottom',
      },
    },
    '.heading-title': {
      display: 'inline',
      fontSize: '22px',
      fontWeight: '900',
      lineHeight: 1.25,
      p: {
        display: 'inline',
      },
      [media.mobile]: {
        fontSize: fontSizes[4],
        lineHeight: '30px',
      },
    },
    '.tagline-text': {
      paddingTop: space[3],
      [media.tablet]: {
        paddingTop: space[4],
      },
    },
    'a:hover': {
      color: 'inherit',
    },
    '.breadcrumb-wrap': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      '.features-list': {
        width: '85%',
        flexGrow: 1,
      },
      '.icon': {
        transition: 'all 0.5s ease',
        color: colors.primary,
        marginRight: '10px',
        marginBottom: '3px',
      },
    },
  })
);

export const TeaserImageCardWrapper = styled.div({
  position: 'relative',
  '> button': {
    position: 'absolute',
    right: '16px',
    top: '16px',
    cursor: 'pointer',
    zIndex: 5,
    '.wishlist-icon': {
      width: '26px',
      height: '26px',
      color: 'white',
    },
    '.button-text': {
      display: 'none',
    },
  },
});

export const RecommendationListWithSlidesNavigationContainer = styled(
  SlidersNavigationContainer
)({
  paddingTop: '80px',
  paddingBottom: '80px',
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
  scrollMarginTop: '100px',
  '.count': {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '20px',
  },
});
