import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { IFetchComponentResponse } from 'components/domain/fdr-page-components/fdr-page-components.types';
import { FdrProductRecommenderSlider } from 'components/domain/fdr-product-recommender';
import { DEFAULTS_RESULT_PER_PAGE } from 'components/domain/fdr-search/fdr-search.constants';
import useLazyFetchPagination from 'components/domain/pagination/useLazyFetchPagination';
import CommonGridRecommendationSection from 'components/domain/recommendations/CommonGridRecommendationSection';
import ProductRecommendationCustomContent from 'components/domain/recommendations/ProductRecommendationsSectionCustomContent';
import {
  PRODUCT_GRID_ITEMS_PER_PAGE,
  ProductRecommendationView,
} from 'components/domain/recommendations/Recommendations.constants';
import {
  FdrProductRecommendation,
  TFdrProductRecommendationProps,
} from './fdr-product-recommendation';
import {
  IFdrRecommenderProduct,
  IFdrRecommenderProductComponent,
} from './fdr-product-recommender.types';

const ProductRecommendationGridItem: React.FC<{
  item: IFdrRecommenderProduct;
}> = ({ item }) => {
  return <FdrProductRecommendation key={item.link.url} {...item} />;
};

interface IFdrProductRecommenderProps {
  component: IFdrRecommenderProductComponent;
  id?: string;
  loadMoreText?: string;
  view?: ProductRecommendationView;
  testId?: string;
  sectionHeaderTitle?: string;
  fetchItems?(
    page: number,
    perPage: number
  ): Promise<IFetchComponentResponse<IFdrRecommenderProduct>>;
  loading?: boolean;
  sliderRecommendationItem?: React.FC<
    PropsWithChildren<TFdrProductRecommendationProps>
  >;
  gridRecommendationItem?: React.FC<
    PropsWithChildren<{
      item: IFdrRecommenderProduct;
    }>
  >;
}

export function FdrProductRecommender({
  component,
  id,
  loadMoreText,
  view = ProductRecommendationView.SLIDER,
  testId,
  children,
  sectionHeaderTitle,
  fetchItems,
  loading,
  sliderRecommendationItem: SliderRecommendationItem,
  gridRecommendationItem: GridRecommendationItem,
}: PropsWithChildren<IFdrProductRecommenderProps>): ReactElement {
  const { mobile } = useDeviceType();
  const perPage =
    view === ProductRecommendationView.SLIDER
      ? DEFAULTS_RESULT_PER_PAGE
      : PRODUCT_GRID_ITEMS_PER_PAGE;

  const { items, page, nextPage, prevPage, setPage, hasMore } =
    useLazyFetchPagination<IFdrRecommenderProduct>(component.items, {
      perPage,
      total: component.total,
      fetchItems,
    });

  const [imageHeight, setImageHeight] = useState<number>();

  const updateImageHeight = useCallback((height: number) => {
    if (height) {
      setImageHeight(height);
    }
  }, []);

  const SliderItemVariant =
    SliderRecommendationItem ?? FdrProductRecommendation;

  if (view === ProductRecommendationView.SLIDER) {
    return (
      <FdrProductRecommenderSlider
        id={id}
        testId={testId}
        sectionTitle={sectionHeaderTitle}
        customContent={
          <ProductRecommendationCustomContent>
            {children}
          </ProductRecommendationCustomContent>
        }
        {...component}
        page={page}
        perPage={perPage}
        prevPage={prevPage}
        nextPage={nextPage}
        setPage={setPage}
        loading={loading}
        imageHeight={imageHeight}
        isSliderPagination={!mobile}
      >
        {items.map((item, index) => (
          <SliderItemVariant
            key={index}
            {...item}
            lazy={index + 1 > perPage}
            updateImageHeight={updateImageHeight}
          />
        ))}
      </FdrProductRecommenderSlider>
    );
  }

  return (
    <CommonGridRecommendationSection<IFdrRecommenderProduct>
      id={id}
      items={items}
      nextPage={nextPage}
      hasMore={hasMore}
      recommendationItem={
        GridRecommendationItem ?? ProductRecommendationGridItem
      }
      loading={loading}
      mainTitle={component.name}
      loadMoreText={loadMoreText}
      testId={testId}
    >
      {children}
    </CommonGridRecommendationSection>
  );
}
