import React, { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { BsIcon } from '@hotelplan/core.basis.bs-icon';
import { FdrRoute } from '@hotelplan/fdr.regular.basis.fdr-route';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import { FdrAgencyOpenStatus } from '@hotelplan/supergraph-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { FdrScheduleOpeningStatus } from 'components/candidate/fdr-schedule';
import { FdrTeaserPlaceholder } from 'components/domain/fdr-teaser-card';
import { FdrImageTeaser } from 'components/domain/fdr-teaser-card/fdr-image-teaser';
import { FdrAgencyItemFragment } from 'schemas/fragment/recommenders/agency/fdr-agency-recommender.generated';
import { ThirdHeading } from 'theme/headings';

const AgencyCardWrapper = styled.div(({ theme: { colors, space, text } }) => ({
  '.heading-title': {
    display: 'inline',
  },
  '.image-card': {
    marginBottom: space[3],
  },
  '.heading-wrap': {
    marginBottom: '12px',
    '.icon': {
      color: colors.primary,
      marginLeft: space[2],
      width: '23px',
      verticalAlign: 'text-bottom',
    },
  },
  '.agency-city': {
    ...text.groupHeading,
    marginBottom: '12px',
  },
  'a:hover': {
    color: 'inherit',
  },
}));

export const FdrAgencyOpeningStatus = styled(FdrScheduleOpeningStatus)<{
  opened: boolean;
}>(({ opened }) =>
  sx2CssThemeFn({
    ml: '20px',
    mt: 1,
    fontSize: [1, 2],
    fontWeight: [null, 'bold'],
    color: opened ? 'success' : 'redWeb',
    '&:before': {
      width: '10px',
      height: '10px',
      left: -20,
    },
  })
);

type TFdrAgencyItemProps = {
  agency: FdrAgencyItemFragment;
  updateImageHeight?: (height: number) => void;
};

export function FdrFlexibleAgencyItem({
  agency,
  updateImageHeight,
}: TFdrAgencyItemProps): ReactElement {
  const { name, webMeta, image, address } = agency;
  const link = webMeta.link;

  const imageRef = useRef<HTMLDivElement>(null);
  const { h } = useElementSize(imageRef);
  useEffect(() => {
    updateImageHeight && updateImageHeight(h);
  }, [h, updateImageHeight]);

  return (
    <AgencyCardWrapper>
      <div ref={imageRef}>
        <FdrImageTeaser cfg={{ link }} image={image} className="image-card" />
      </div>
      <FdrRoute link={link}>
        <span className="agency-city">{address.city}</span>
        <div className="heading-wrap">
          <ThirdHeading className="heading-title">{name}</ThirdHeading>
          <BsIcon name={'chevron-long-right'} />
        </div>
      </FdrRoute>
    </AgencyCardWrapper>
  );
}

export function FdrAgencyOverviewItem({
  agency,
}: TFdrAgencyItemProps): ReactElement {
  const { webMeta, name, image, address, openStatus } = agency;
  const link = webMeta.link;

  const opened = openStatus !== FdrAgencyOpenStatus.Close;

  return (
    <AgencyCardWrapper>
      <FdrImageTeaser
        image={image}
        cfg={{ link, title: name }}
        className="image-card"
      />
      {address ? (
        <FdrRoute link={link}>
          <span>{address.street}</span>
          <p>{`${address.zip} ${address.city}`}</p>
          {opened !== null ? (
            <FdrAgencyOpeningStatus showStatusIcon opened={opened} />
          ) : null}
        </FdrRoute>
      ) : null}
    </AgencyCardWrapper>
  );
}

export function FdrAgencyItemPlaceholder(): ReactElement {
  return (
    <AgencyCardWrapper>
      <FdrTeaserPlaceholder />
      <span>&nbsp;</span>
      <br />
      <span>&nbsp;</span>
    </AgencyCardWrapper>
  );
}
