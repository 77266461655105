import React, { useCallback } from 'react';
import { isFdrThGeoObject } from 'components/domain/fdr-geo/fdr-geo.utils';
import {
  createPageComponentVariables,
  mapFdrPageComponentsResponseToGeo,
} from 'components/domain/fdr-page-components/fdr-page-components.utils';
import { FdrCommonGridRecommender } from 'components/domain/fdr-page-components/recommenders/common/fdr-common-grid-recommender';
import useFdrPageComponentsFetch from 'components/domain/fdr-page-components/useFdrPageComponentsFetch';
import { FdrPageComponentsFragment } from 'schemas/fragment/page-components/fdr-page-components.generated';
import { FdrPaginatedGeoRecommenderDocument } from 'schemas/query/geo/fdr-paginated-geo-recommender.generated';
import { FdrGeoRecommenderItem } from './fdr-geo-recommender-item';

interface IFdrGeoPageComponent {
  component: Extract<
    FdrPageComponentsFragment['components'][number],
    { __typename: 'FdrGeoRecommender' }
  >;
}

const FdrGeoPageComponent: React.FC<IFdrGeoPageComponent> = ({ component }) => {
  const mapVariables = useCallback(
    (page, perPage) => {
      return createPageComponentVariables(component.id, page, perPage);
    },
    [component.id]
  );

  const { fetchItems } = useFdrPageComponentsFetch(
    FdrPaginatedGeoRecommenderDocument,
    mapVariables,
    mapFdrPageComponentsResponseToGeo
  );

  return (
    <FdrCommonGridRecommender
      bgColor={component.bgColor}
      fontColor={component.fgColor}
      title={component.title}
      items={component.itemsPage.items.map(item => ({
        ...item,
        link:
          isFdrThGeoObject(item) && item.webMeta.descriptionWebMeta?.link
            ? item.webMeta.descriptionWebMeta.link
            : undefined,
      }))}
      pagination={component.itemsPage.pagination}
      recommendationItem={FdrGeoRecommenderItem}
      fetchItems={fetchItems}
    />
  );
};

export default FdrGeoPageComponent;
