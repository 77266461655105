import React, { ReactElement, useEffect, useRef } from 'react';
import { Icon } from '@hotelplan/components.common.icon';
import { useElementSize } from '@hotelplan/libs.hooks-dom';
import FdrResponsiveImgWithCopyright from 'components/domain/fdr-copyright/fdr-responsive-img-with-copyright';
import { FdrImageTeaser } from 'components/domain/fdr-teaser-card/fdr-image-teaser';
import { fdrBlogSpecs } from 'components/util/criterias/blog.dmc';
import { FdrBlogArticleFragment } from 'schemas/fragment/blog-articles/fdr-all-blog-articles.generated';
import { ThirdHeading } from 'theme/headings';
import {
  BlogArticleChevronWrapper,
  BlogArticleContent,
  BlogArticleContentWrapper,
  BlogArticleDescription,
  BLogArticleMediaTeaser,
  FdrArticleInfoWrapper,
} from './fdr-blog-article.styles';

interface IBlogArticleItem {
  item: FdrBlogArticleFragment;
  onImageHeightChange?: (height: number) => void;
}

const CHEVRON_ICON = <Icon name="chevron-long-left" className="chevron-icon" />;

function FdrBlogArticleItem({
  item,
  onImageHeightChange,
}: IBlogArticleItem): ReactElement {
  const imageRef = useRef<HTMLDivElement>(null);
  const { h: imageHeight } = useElementSize(imageRef);
  const { hero, employee, start, title, text } = item;

  useEffect(() => {
    onImageHeightChange && onImageHeightChange(imageHeight);
  }, [imageHeight, onImageHeightChange]);

  const heroElement = hero && (
    <FdrResponsiveImgWithCopyright image={hero} specs={fdrBlogSpecs} />
  );

  return (
    <BlogArticleContentWrapper>
      <BlogArticleContent className="blog-article-main">
        <div ref={imageRef}>
          {hero ? (
            <>
              <BLogArticleMediaTeaser
                icon={CHEVRON_ICON}
                image={heroElement}
                className="hero-image"
              />
              <FdrArticleInfoWrapper
                image={employee.image}
                className="author-image"
              >
                <FdrBlogArticleInfo name={employee.name} date={start} />
              </FdrArticleInfoWrapper>
            </>
          ) : (
            <FdrImageTeaser image={employee.image} className="author-image">
              <FdrBlogArticleInfo name={employee.name} date={start} />
            </FdrImageTeaser>
          )}
        </div>
        <div className="heading-wrap">
          <ThirdHeading className="heading-title">{title}</ThirdHeading>
        </div>
        <BlogArticleDescription>
          <div
            className="lead-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <BlogArticleChevronWrapper>
            <Icon name={'chevron-long-right'} />
          </BlogArticleChevronWrapper>
        </BlogArticleDescription>
      </BlogArticleContent>
    </BlogArticleContentWrapper>
  );
}

export default FdrBlogArticleItem;

interface IBlogArticleInfo {
  name: string;
  date?: string;
}

const FdrBlogArticleInfo: React.FC<IBlogArticleInfo> = ({ name, date }) => {
  const dateString = new Date(date).toLocaleDateString(`de-DE`, {
    month: '2-digit',
    year: 'numeric',
    day: '2-digit',
  });

  return (
    <>
      <address>{name}</address>
      {date?.length ? <time dateTime={date}>{dateString}</time> : null}
    </>
  );
};
